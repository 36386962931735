.eventTimelineRow {
  display: flex;
  align-items: center;
  gap: 25px;
}
.eventTimelineRow .ant-timeline-item-tail {
  border-left: 3px solid #c4c4c4;
}
.eventTimelineRow:last-child .ant-timeline-item-tail {
  height: 0;
}
.eventTimelineRow:last-child .timeline-item {
  padding-bottom: 0;
}
.eventTimelineRow .time {
  align-self: flex-start;
  flex: 0 0 50px;
  width: 45px;
  color: #8c8c8c;
  font-size: 13px;
  margin-top: -4px;
  cursor: pointer;
}

.eventTimelineRow.eventTimelineRowSelected .time, .eventTimelineRow.eventTimelineRowSelected .timeline-item .name {
  color: #1a90ff;
}

.timeline-item .ant-timeline-item-head {
  padding: 0;
}
.timeline-item .textContainer {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  white-space: nowrap;
  text-wrap: wrap;
  word-break: break-word;
  margin-top: -1px;
}
.timeline-item .name {
  color: #222222;
  font-size: 15px;
  padding-top: 1px;
  cursor: pointer;
}
.timeline-item .additional {
  color: #8c8c8c;
}

.event-icon {
  height: 20px;
  margin: -1px 10px;
  overflow: visible;
  cursor: pointer;
}