.highcharts-dashboards-wrapper {
  overflow: hidden;
  min-height: calc(100vh - 150px);
}

.highcharts-dashboards-component {
  min-height: calc(100vh - 150px);
}

.eventsExplorerPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
}
.eventsExplorerPage .filterPanelContainer {
  display: inline-flex;
  width: 100%;
  height: 4rem;
  margin-bottom: 20px;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  background: #FFF;
}
.eventsExplorerPage .filterPanelContainer > * {
  margin: 0 5px;
}
.eventsExplorerPage .filterPanelContainer > *:first-child {
  margin-left: 0;
}
.eventsExplorerPage .filterPanelContainer > *:last-child {
  margin-right: 0;
}
.eventsExplorerPage .filterPanelContainer .date-filter {
  min-width: 220px;
}
.eventsExplorerPage .filterPanelContainer .ant-select, .eventsExplorerPage .filterPanelContainer .ant-btn {
  height: 40px;
}
.eventsExplorerPage .filterPanelContainer .ant-select .ant-select-selection--multiple, .eventsExplorerPage .filterPanelContainer .ant-btn .ant-select-selection--multiple {
  height: 40px;
}
.eventsExplorerPage .filterPanelContainer .ant-select .ant-select-selection--multiple .ant-select-selection__clear, .eventsExplorerPage .filterPanelContainer .ant-btn .ant-select-selection--multiple .ant-select-selection__clear {
  margin-top: -3px;
}
.eventsExplorerPage .filterPanelContainer .ant-select .ant-select-selection--multiple .ant-select-selection__choice, .eventsExplorerPage .filterPanelContainer .ant-btn .ant-select-selection--multiple .ant-select-selection__choice {
  height: 32px;
}
.eventsExplorerPage .filterPanelContainer .ant-select .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__content, .eventsExplorerPage .filterPanelContainer .ant-btn .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__content {
  line-height: 30px;
}
.eventsExplorerPage .filterPanelContainer .ant-select .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field, .eventsExplorerPage .filterPanelContainer .ant-btn .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  height: 30px;
}
.eventsExplorerPage .filterPanelContainer .info {
  min-width: 120px;
}
.eventsExplorerPage .filterPanelContainer .filter-selected .ant-select-selection {
  border-color: #40A9FF;
}
.eventsExplorerPage .filterPanelContainer .button-content {
  white-space: nowrap;
}
.eventsExplorerPage .filterPanelContainer .button-content .ant-spin {
  margin-right: 5px;
}
.eventsExplorerPage .highcharts-dashboards-wrapper {
  margin: -12px;
  padding: 0;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component {
  margin: 0;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .highcharts-map-navigation.highcharts-zoom-in {
  translate: 0 -12px;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .highcharts-map-navigation .highcharts-button-symbol {
  stroke-width: 5px;
  scale: 72%;
  translate: 6px 6px;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-0 {
  fill: #BBB !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-0.eventPoint {
  fill: #BFBFBF !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-1 {
  fill: #BBB !important;
  stroke: #1890FF !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-1.eventPoint {
  fill: #1890FF !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-2 {
  fill: #BBB !important;
  stroke: #73D13D !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-2.eventPoint {
  fill: #73D13D !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-3 {
  fill: #BBB !important;
  stroke: #FFC53D !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-3.eventPoint {
  fill: #FFC53D !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-4 {
  fill: #BBB !important;
  stroke: #F5222D !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .severity-4.eventPoint {
  fill: #F5222D !important;
  stroke: #000 !important;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .highcharts-tooltip {
  pointer-events: all;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .highcharts-tooltip text .b {
  font-weight: bold;
}
.eventsExplorerPage .highcharts-dashboards-wrapper .highcharts-dashboards-cell > .highcharts-dashboards-component .highcharts-tooltip text a {
  fill: #1890FF;
}
.eventsExplorerPage .dashboardPanelContainer {
  flex-grow: 1;
  height: 40rem;
}