@import url("https://code.highcharts.com/css/highcharts.css");
@import url("https://code.highcharts.com/dashboards/css/datagrid.css");
@import url("https://code.highcharts.com/dashboards/css/dashboards.css");
h1#title {
  padding-top: 10px;
  margin: 0;
  background-color: #3d3d3d;
  text-align: center;
  color: #fff;
}

.highcharts-datagrid-container {
  overflow-y: auto;
  height: 600vh;
}