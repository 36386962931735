.ant-popover .map-layers-checkbox span.ant-checkbox.ant-checkbox-checked:before {
  content: "";
  background-size: 10px 8px;
  background-repeat: no-repeat;
  background-position: 3px 4px;
  position: absolute;
  z-index: 2;
  color: #3b98fb;
  border: solid #171010;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 7px;
  height: 15px;
  left: 6px;
  top: 2px;
  opacity: 1;
}
.ant-popover .map-layers-checkbox label.ant-checkbox-wrapper:hover {
  background-color: #f8f8f8;
}
.ant-popover .map-layers-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border-color: transparent;
  opacity: 0;
  z-index: -1;
}
.ant-popover .map-layers-checkbox .ant-checkbox-inner:after {
  background-color: transparent;
}
.ant-popover .map-layers-checkbox .ant-checkbox-inner {
  border-color: transparent;
}
.ant-popover .map-layers-checkbox .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
  border-color: transparent;
}
.ant-popover .map-layers-checkbox label.ant-checkbox-wrapper {
  width: 100%;
  min-width: 200px;
  padding: 10px 15px;
}
.ant-popover .map-layers-checkbox span.ant-checkbox {
  float: right;
  position: relative;
}
.ant-popover span,
.ant-popover label {
  outline: none;
}
.ant-popover .ant-popover-inner-content {
  padding: 0;
  overflow-y: auto;
  max-height: 70vh;
}
.ant-popover label.ant-checkbox-wrapper:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-popover label.ant-checkbox-wrapper:last-child {
  border-radius: 0 0 4px 4px;
}

.ant-checkbox-inner {
  background-color: transparent;
}

.ant-popover {
  z-index: 9999;
}