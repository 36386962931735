.flightExplorer {
  background-color: transparent;
  height: calc(100vh - 74px);
  padding: 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.flightExplorer.v-scroll {
  overflow-y: scroll;
}
.flightExplorer .topContainer {
  flex: 1;
  min-height: 400px;
  height: calc(100vh - 10px - 30px - 10px - 10px - 500px - 10px - 64px);
}
.flightExplorer .topContainer .content {
  display: flex;
  height: 100%;
  flex-direction: row;
}
.flightExplorer .topContainer .content .mapView {
  flex: 1 1 auto;
}
.flightExplorer .bottomContainer {
  display: flex;
  flex-direction: row;
  flex: 0 0 400px;
  width: 100%;
}
.flightExplorer .bottomContainer .parameters-container {
  flex-direction: column;
  width: 15rem;
}
.flightExplorer .bottomContainer .chart-container {
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}
.flightExplorer .bottomContainer .no-data-container {
  width: 100%;
}
.flightExplorer .bottomContainer .content {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.flightExplorer .bottomContainer .content .navBar {
  height: 100px;
}
.flightExplorer .bottomContainer .parameterGroup {
  display: flex;
  flex-direction: row;
}
.flightExplorer .bottomContainer .parameterGroup .ant-checkbox-group {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}
.flightExplorer .bottomContainer .parameterGroup .ant-checkbox-group .ant-checkbox-group-item {
  margin-top: 0.5rem;
}
.flightExplorer .overlay-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  transform: translateY(-100%);
}
.flightExplorer .fill-parent {
  width: 100%;
  height: 100%;
}
.flightExplorer .fill-parent.hidden {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.flightExplorer .align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.flightExplorer .sisense-chart-container {
  overflow-y: auto;
  width: 100vw;
  height: 400px;
  align-items: end;
  flex-direction: column;
}
.flightExplorer .shadow {
  box-shadow: 0 2px 12px 3px rgba(0, 0, 0, 0.12);
}