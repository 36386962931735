.flight-tracklog-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flight-tracklog-viewer .multiViewContainer {
  flex: 1;
  height: calc(100% - 1rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.flight-tracklog-viewer .multiViewContainer .map2DViewContainer {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: inherit;
}
.flight-tracklog-viewer .multiViewContainer .map2DViewContainer .map-wrapper__map {
  /* Override some original map stylings so we can layout properly with flex */
  height: inherit;
}
.flight-tracklog-viewer .multiViewContainer .map3DViewContainer {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: inherit;
}
.flight-tracklog-viewer .pointDetailPanel {
  flex: 0 0 10%;
}

.hidden {
  display: none !important;
}