.filter-list-table {
  background: #fff;
  width: 261px;
  display: block;
  float: left;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  box-shadow: 3px 2px 17px -6px rgba(0, 0, 0, 0.75);
  position: relative;
  z-index: 1;
  transition: all 0.5s ease;
}
.filter-list-table td,
.filter-list-table th {
  padding: 7px 5px 7px 16px !important;
  word-break: break-word !important;
}
.filter-list-table td:first-child,
.filter-list-table th:first-child {
  width: 175px;
}
.filter-list-table td:last-child,
.filter-list-table th:last-child {
  padding-left: 0 !important;
}
.filter-list-table th {
  height: 30px;
  background: #fafafa !important;
  text-align: right !important;
  color: #4872de !important;
}
.filter-list-table th:hover {
  background-color: #fafafa !important;
}
.filter-list-table th.ant-table-column-has-actions.ant-table-column-has-filters div {
  font-weight: normal;
}
.filter-list-table tr {
  cursor: pointer;
}
.filter-list-table tr td:hover {
  color: #1890ff;
}
.filter-list-table tr td > div > div:first-child {
  color: rgba(0, 0, 0, 0.65);
}
.filter-list-table .ant-table-column-sorters:hover:before {
  background: none !important;
}
.filter-list-table .ant-table-column-sorter {
  display: none;
}
.filter-list-table .ant-table-filter-icon.ant-dropdown-trigger {
  width: 40%;
  color: #4872de;
  text-align: right;
  padding-top: 5px;
  margin-right: 4px;
  font-size: 14px;
  background: none !important;
}
.filter-list-table .ant-table-filter-icon.ant-dropdown-trigger:hover {
  color: #4872de !important;
}
.filter-list-table .ant-table-filter-icon.ant-dropdown-trigger::after {
  content: "arrow_right";
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 18px;
  vertical-align: bottom;
  color: #4872de;
}
.filter-list-table .ant-table-filter-icon.ant-dropdown-trigger.ant-dropdown-open {
  color: #4872de;
}
.filter-list-table .ant-table-filter-icon.ant-dropdown-trigger.ant-dropdown-open::after {
  content: "arrow_drop_down";
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 18px;
  vertical-align: bottom;
  color: #4872de;
}
.filter-list-table .ant-table-filter-open {
  color: #4872de !important;
}

.selected-row {
  color: #1890ff;
  background-color: #e6f7ff;
}
.selected-row td {
  border-right: 3px solid #1890ff;
}