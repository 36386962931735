.controlsPanelContainer {
  position: fixed;
  left: 45px;
  bottom: 0;
  display: inline-flex;
  width: calc(100% - 45px);
  align-self: flex-end;
  align-items: center;
  height: 4rem;
  background: #fff;
  justify-content: space-evenly;
}

.playerControlsContainer {
  width: 10rem;
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 1em;
  size: 0.5rem;
  gap: 0.5em;
}

.playerSpeedControlContainer {
  width: 1rem;
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 1em;
  size: 0.5rem;
  gap: 0.5em;
}

.playerTimelineContainer {
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 1em;
  size: 0.5rem;
  gap: 0.5em;
}

.player-control-enabled:hover {
  cursor: pointer;
}