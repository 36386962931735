.events-config-drawer .form-item-short-1 {
  width: 25rem;
}
.events-config-drawer .form-item-short-1 .ant-form-item-label {
  display: table;
  width: 8rem;
  padding-top: 5px;
}
.events-config-drawer .form-item-short-1 .ant-form-item-control {
  width: 25rem;
}
.events-config-drawer .form-item-short-1 .severity-low {
  outline: 2px solid green;
}
.events-config-drawer .form-item-short-1 .severity-medium {
  outline: 2px solid orange;
}
.events-config-drawer .form-item-short-1 .severity-high {
  outline: 2px solid red;
}
.events-config-drawer .form-item-short-1 .ant-form-item-control-wrapper {
  width: 200px;
}